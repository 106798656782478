import React, { memo, useState } from "react";
import css from "../Modulecss/Home.module.css";
import findGif from "../css/loading_old.gif";
import { useHistory } from "react-router-dom";
const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
const nodeMode = process.env.NODE_ENV;

if (nodeMode === "development") {
  var baseUrl = beckendLocalApiUrl;
} else {
  baseUrl = beckendLiveApiUrl;
}
const BetCard = React.memo(({ allgame, user, deletegame, isdelete }) => {
  const [webgame, setWebgame] = useState(false);
  const history = useHistory();
  const playgame = () => {
    if (allgame.Game_type == "ludoking") {
      history.push("/viewgame1/" + allgame._id);
    } else {
      history.push("/webgame/" + allgame._id);
    }
  };

  return (
    <div className={`${css.betCard} mt-2`}>
      <span
        className={`${css.betCardTitle} pl-3 d-flex align-items-center text-uppercase ${css.betTitleDiv}`}
      >
        Currently Playing with:
        <span className="ml-1" style={{ color: "brown" }}>
          {allgame.Accepetd_By ? allgame.Accepetd_By.Name : "Finding"}
        </span>
      </span>
      <div className={`d-flex pl-3 ${css.betBodyDiv}`}>
        <div className="pr-3 pb-1">
          <span className={css.betCardSubTitle}>Entry Fee</span>
          <div>
            <img
              src={
                process.env.PUBLIC_URL +
                "/Images/LandingPage_img/global-rupeeIcon.png"
              }
              alt=""
              width="21px"
            />
            <span className={css.betCardAmount}>{allgame.Game_Ammount}</span>
          </div>
        </div>
        <div>
          <center>
            <div>
              {(allgame.Game_type == "ludoking" && (
                <img
                  src={process.env.PUBLIC_URL + "/Images/ludoking.png"}
                  alt=""
                  style={{ marginTop: "10px", marginLeft: "10px" }}
                  width="100px"
                />
              )) || (
                <img
                  src={process.env.PUBLIC_URL + "/Images/ludozo.png"}
                  alt=""
                  style={{ marginTop: "10px", marginLeft: "10px" }}
                  width="100px"
                />
              )}
            </div>
          </center>
        </div>
        {(user == allgame.Created_by._id || user == allgame.Accepetd_By._id) &&
          allgame.Status == "new" && (
            <div className="text-center col-7 ml-auto mt-auto mb-auto">
              <div className="pl-2 text-center">
                <img src={findGif} style={{ width: "15px", height: "15px" }} />
              </div>
              <div style={{ lineHeight: 1 }}>
                <span className={css.betCard_playerName}>Finding Player!</span>
              </div>
              {isdelete && (
                <button
                  onClick={() => {
                    deletegame(allgame._id);
                  }}
                  className={`d-flex justify-content-center ${css.playButton} ${css.cxy} bg-danger btn-sm`}
                >
                  Delete
                </button>
              )}
            </div>
          )}
        {(user === allgame.Created_by._id ||
          (allgame.Accepetd_By && user === allgame.Accepetd_By._id)) &&
          allgame.Accepetd_By &&
          allgame.Status === "running" && (
            <button
              onClick={playgame}
              className={` ${css.playButton} ${css.cxy} bg-success  btn-sm`}
            >
              View
            </button>
          )}{" "}
      </div>
    </div>
  );
});

export default memo(BetCard);
