import React, { memo } from "react";
import css from "../Modulecss/Home.module.css";
const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
const nodeMode = process.env.NODE_ENV;
if (nodeMode === "development") {
  var baseUrl = beckendLocalApiUrl;
} else {
  baseUrl = beckendLiveApiUrl;
}
const BetCard = React.memo(({ amount, AcceptChallang, playing }) => {
  return (
    <div className={`${css.betCard} mt-2`}>
      <span
        className={`${css.betCardTitle} pl-3 d-flex align-items-center text-uppercase ${css.betTitleDiv}`}
      >
        Currently Playing:
        <span className="ml-1" style={{ color: "brown" }}>
          {playing}
        </span>
      </span>
      <div className={`d-flex pl-3 ${css.betBodyDiv}`}>
        <div className="pr-3 pb-1">
          <span className={css.betCardSubTitle}>Entry Fee</span>
          <div>
            <img
              src={
                process.env.PUBLIC_URL +
                "/Images/LandingPage_img/global-rupeeIcon.png"
              }
              alt=""
              width="21px"
            />
            <span className={css.betCardAmount}>{amount}</span>
          </div>
        </div>
        <button
          className={`${css.bgSecondary} ${css.playButton} ${css.cxy} btn-sm`}
          onClick={() => AcceptChallang(amount)}
        >
          Play
        </button>
      </div>
    </div>
  );
});

export default memo(BetCard);
