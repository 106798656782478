import React from "react";
import { NavLink } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer2">
      <NavLink to="/profile" className="footer-link">
        <i className="bi bi-people"></i>
      </NavLink>
      <NavLink to="/" className="footer-link homel">
        <i className="bi bi-house"></i>
      </NavLink>
      <NavLink to="/Addcase" className="footer-link">
        <i className="bi bi-wallet"></i>
      </NavLink>
    </div>
  );
};

export default Footer;
